.home {
  &__background {
    // background: red;
    // background-image: url("https://www.saxal.me/gray_background.png");
    min-height: 100vh;
    width: 100%;
    background-size: cover;
    position: relative;

    -webkit-transition: background 1s linear;
    -moz-transition: background 1s linear;
    -o-transition: background 1s linear;
    -ms-transition: background 1s linear;
    transition: background 1s linear;
  }

  &__container {
    display: flex;
    flex-direction: row;

    @media (max-width: 860px) {
      display: block;
    }
  }

  &__title-container {
    padding: 80px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    align-items: flex-start;

    @media (max-width: 860px) {
      display: block;
      min-height: auto;
      padding: 20px;
    }
  }

  &__cards-container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    max-width: 600px;

    @media (max-width: 860px) {
      display: block;
      min-height: auto;
    }
  }

  &__title {
    color: white;
    line-height: 110px !important;
    font-size: 120px !important;

    @media (max-width: 860px) {
      font-size: 86px !important;
      line-height: 81px !important;
    }

    // text-shadow: 0 0 1px #000000, 0 0 1px #000000;
  }

  &__subtitle {
    color: white !important;
    font-weight: 300 !important;
    font-size: 22px !important;
    margin-top: 10px !important;
    margin-bottom: 20px !important;
    max-width: 450px;

    @media (max-width: 860px) {
        font-size: 17px !important;
    }
  }

  &__email {
    display: flex;
    flex-direction: row-reverse;
    margin-right: 80px;
    align-items: center;

    &-animation {
      width: 200px;
      margin-left: 20px;
    }

    justify-content: center;

    > h1 {
      text-decoration: dotted;
    }

    @media (max-width: 860px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 0px;

        &-animation {
            margin-left: 0px;
         }
    }
  }

  &__card {
    border-radius: 20px;
    height: 400px;
    width: 300px;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    // justify-content: flex-end;
    align-items: center;
    padding: 20px;
    position: relative;
    transform: scale(0.8);
    transition: all 300ms;
    filter: grayscale(100%);

    &.active {
      transform: scale(1);
      filter: grayscale(0%);
    }
  }

  &__card-gradient {
    border-radius: 20px;
    background: #ffffff;
    background: linear-gradient(180deg, #ffffff33, #01010166);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
  }

  &__card-title {
    color: white !important;
    z-index: 1;
  }

  &__card-description {
    color: white !important;
    z-index: 1;
  }
}

.line {
  width: 40px;
  height: 6px;
  background-color: white;
}

// home card

// #FF9800
