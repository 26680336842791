.navbar {
    height: 80px;
    width: "100%";
    // background-color: aqua;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding-left: 60px;
    padding-right: 80px;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 860px) {
        position: relative;
        padding-left: 20px;
        padding-right: 20px;
      }

    &__logo {
        height: 80px;
        width: 80px;
        background-size: cover;
    }

    &__email {
        height: 30px;
        width: 30px;
        margin-bottom: 3px;
    }

    &__link {
        color: white !important;
        margin-right: 20px !important;
        font-size: 18px !important;
        font-weight: 600 !important;
        letter-spacing: 2px;
        font-family: "Bebas Neue", sans-serif;

        > a {
            padding-bottom: 5px;
            border-bottom: 3px solid transparent;
            transition: border-color .2s;
        
            &:hover {
                // color: #FF9800 !important;
                border-color: white;
            }
        }
    }

    &__row {
        display: flex;
        align-items: center;
    }
}