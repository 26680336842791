.login-page {
  background: url("../../images/login_background.jpeg");
  min-height: 100vh;
  background-size: cover;
  position: relative;

  &__filter {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    backdrop-filter: blur(8px) opacity(1);
  }
}
